import Vue from 'vue';
import TYPES from '@/types';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import UploadVoucherSpecialContributionCommand
  from '@/modules/my-investment/allianz/special-contribution/application/commands/upload-voucher-special-contribution-command';

// Domain
import { UploadVoucherDto }
  from '@/modules/my-investment/allianz/special-contribution/domain/dto/upload-voucher-dto';
import {
  WealthFlexibleDepositStateManager,
} from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class AttachYourProofViewModel {
  @Inject(TYPES.UPLOAD_VOUCHER_SPECIAL_CONTRIBUTION_COMMAND)
  private readonly upload_voucher_special_contribution_command!:
    UploadVoucherSpecialContributionCommand;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  private readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly LINK_WHATSAPP_SOWOS = 'https://wa.me/message/O7PYWADEGRAXO1';

  readonly view: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.attach-your-proof';

  readonly limit_file_size = 4 * 1024 * 1024;

  readonly minimum_file_size = 0.1;

  readonly allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  readonly attached_proof_file_accept = 'image/png, image/jpeg, image/jpg';

  attached_proof_file: File | null = null;

  invalid_file = true;

  is_loading = false;

  upload_voucher_dto: UploadVoucherDto = {
    file_name: '',
    mime_type: '',
    upload_date: '',
    file_data: '',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  public constructor(view: Vue) {
    this.view = view;
  }

  get styledFileName(): string {
    if (this.attached_proof_file) {
      return `${this.attached_proof_file.name.slice(0, 30)}${this.attached_proof_file.name.length > 35 ? '...' : ''}`;
    }

    return this.translate('attach_placeholder');
  }

  get styledFileSize(): string {
    if (this.attached_proof_file) {
      return convertToLargerPossibleUnit(this.attached_proof_file.size);
    }

    return '0 kb';
  }

  handleVoucher = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && file.size >= this.minimum_file_size
        && this.allowed_extensions.indexOf(file.type) > -1) {
        this.attached_proof_file = file;
        this.invalid_file = false;
      } else {
        this.attached_proof_file = null;
        this.invalid_file = true;
        this.message_notifier.showErrorNotification(this.translate('errors.invalid_file'));
      }
    }
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  prevStep() {
    this.view.$emit('prevStep');
  }

  uploadVoucher = async () => {
    try {
      if (this.attached_proof_file) {
        this.is_loading = true;
        const base64_file = await this.convertFileToBase64(this.attached_proof_file);
        this.upload_voucher_dto = {
          file_name: this.attached_proof_file.name,
          mime_type: this.attached_proof_file.type,
          upload_date: new Date().toISOString(),
          file_data: String(base64_file!),
        };
        const voucher_extracted_data = await this
          .upload_voucher_special_contribution_command.internalExecute(this.upload_voucher_dto);
        const { deposit } = this.wealth_flexible_deposit_state_manager.state;
        this.wealth_flexible_deposit_state_manager.patch({
          deposit: { ...deposit, amount: voucher_extracted_data.deposit_amount },
        });
        this.view.$emit('nextStep');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.upload_voucher_special_contribution_command'));
    } finally {
      this.is_loading = false;
    }
  }
}
