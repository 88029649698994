









































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AttachYourProofViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/special-deposits/attach-your-proof-view-model';

@Component({ name: 'AttachYourProof' })
export default class AttachYourProof extends Vue {
  special_deposit_info_view_model = Vue.observable(
    new AttachYourProofViewModel(this),
  );
}
